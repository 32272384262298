<template>
  <v-dialog v-model="isActive" persistent :width="width">
    <v-card>
      <v-toolbar flat color="black" dark>
        <v-toolbar-title>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline ma-0 pa-0"> Edycja użytkownika </v-list-item-title>
              <v-list-item-subtitle class="mt-n4">
                Tutaj możesz zmienić dane użytkownika
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="py-5 grey--text text--darken-4">
        <v-form ref="formUserData">
          <v-row align="center">
            <v-col cols="8" sm="8" md="8">
              <v-text-field
                v-model="editingUserData.contractor_number"
                label="Numer kontrahenta"
                :rules="[
                  v => !!v || 'Pole jest wymagane',
                  v =>
                    new RegExp('^[0-9]{1,10}$').test(v) ||
                    'Numer kontrahenta wydaje się być niepoprawny',
                ]"
                maxlength="10"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-btn color="primary" @click="getUserInfo()"> Sprawdź </v-btn>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="6">
              <v-text-field
                v-model="editingUserData.login"
                label="Login"
                :rules="rules.loginRule"
                maxlength="30"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="6">
              <v-text-field
                v-model="editingUserData.email"
                label="Email"
                :rules="rules.emailRule"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editingUserData.name"
                label="Imię"
                :rules="rules.nameRule"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editingUserData.surname"
                label="Nazwisko"
                :rules="rules.surnameRule"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editingUserData.birth_date"
                label="Data urodzenia (opcjonalne)"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editingUserData.phone"
                label="Nr telefonu"
                type="tel"
                :rules="rules.phoneRule"
                v-mask="'### ### ###'"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editingUserData.postcode"
                label="Kod pocztowy"
                :rules="rules.postcodeRule"
                v-mask="'##-###'"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="editingUserData.city"
                label="Miasto"
                :rules="rules.notEmptyString"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12" align="center">
              <v-btn-toggle v-model="editingUserData.isCompany" mandatory rounded>
                <v-btn :value="false" width="170"> Osoba prywatna </v-btn>
                <v-btn :value="true" width="170"> Firma </v-btn>
              </v-btn-toggle>
            </v-col>

            <template v-if="editingUserData.isCompany">
              <v-col cols="12" sm="12" md="12" lg="6">
                <v-text-field
                  v-model="editingUserData.company_name"
                  label="Nazwa firmy"
                  :rules="rules.notEmptyString"
                  maxlength="150"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  v-model="editingUserData.company_postcode"
                  label="Kod pocztowy"
                  :rules="rules.postcodeRule"
                  v-mask="'##-###'"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  v-model="editingUserData.company_city"
                  label="Miasto"
                  :rules="rules.notEmptyString"
                  maxlength="150"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  v-model="editingUserData.company_address"
                  label="Adres"
                  :rules="rules.notEmptyString"
                  maxlength="150"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                  v-model="editingUserData.NIP"
                  label="NIP"
                  v-mask="'##########'"
                  :rules="[
                    v => !!v || 'Pole jest wymagane',
                    validNIP() || 'NIP ma niepoprawny format',
                  ]"
                  dense
                ></v-text-field>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="isActive = false"
        >
          Anuluj
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          v-tilt
          x-large
          @click="submit"
        >
          Edytuj
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <InfoSnackBar v-model="info"></InfoSnackBar>

    <!-- progress circular -->
    <ProgressCircularDialog v-model="loading"></ProgressCircularDialog>
  </v-dialog>
</template>

<script>
  import moment from 'moment';
  import axios from 'axios';
  import { mask } from 'vue-the-mask';
  import InfoSnackBar from './InfoSnackBar.vue';
  import ProgressCircularDialog from './ProgressCircularDialog.vue';

  export default {
    name: 'EditUser',
    components: {
      InfoSnackBar,
      ProgressCircularDialog,
    },
    directives: {
      mask,
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      user: {
        type: Object,
      },
      width: {
        type: String,
        default: '620px',
      },
    },
    data: () => ({
      info: null,
      loading: false,
      editingUserData: {
        contractor_number: '',
        user_id: '',
        name: '',
        surname: '',
        login: '',
        email: '',
        phone: '',
        postcode: '',
        city: '',
        birth_date: null,
        company: '',

        isCompany: false,
        company_name: '',
        company_postcode: '',
        company_city: '',
        company_address: '',
        NIP: '',
      },

      defaultEmptyUserData: {
        contractor_number: '',
        user_id: '',
        name: '',
        surname: '',
        login: '',
        email: '',
        phone: '',
        postcode: '',
        city: '',
        birth_date: null,
        company: '',

        isCompany: false,
        company_name: '',
        company_postcode: '',
        company_city: '',
        company_address: '',
        NIP: '',
      },
      rules: {
        notEmptyString: [v => !!v || 'Pole jest wymagane'],
        nameRule: [
          v => !!v || 'Pole jest wymagane',
          v =>
            /^[a-zA-Z_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ ]{1,50}$/.test(v) ||
            'Imię ma niepoprawny format (maksymalnie 50 znaków, bez cyfr)',
        ],
        surnameRule: [
          v => !!v || 'Pole jest wymagane',
          v =>
            /^[a-zA-Z_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ ]{1,50}$/.test(v) ||
            'Nazwisko ma niepoprawny format (maksymalnie 50 znaków, bez cyfr)',
        ],
        postcodeRule: [
          v => !!v || 'Pole jest wymagane',
          v => /^[0-9]{2}-[0-9]{3}$/.test(v) || 'Kod pocztowy ma niewłaściwy format',
        ],
        phoneRule: [
          v => !!v || 'Pole jest wymagane',
          v =>
            /^(\+[0-9]{2}[-. ]?)?[0-9]{3}[-. ]?[0-9]{3}[-. ]?[0-9]{3}$/.test(v) ||
            'Wprowadź poprawny nr telefonu',
        ],
        emailRule: [
          v => !!v || 'Pole jest wymagane',
          v =>
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
              v
            ) || 'Wprowadzono niepoprawny adres email',
        ],
        loginRule: [
          v => !!v || 'Pole jest wymagane',
          v => (v.length > 7 && v.length < 31) || 'Login musi mieć minmum 8 znaków',
        ],
      },
    }),
    watch: {
      isActive: function (next) {
        if (next === true) this.getData();
        else this.editingUserData = JSON.parse(JSON.stringify(this.defaultEmptyUserData));
      },
    },
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
    methods: {
      validNIP: function () {
        if (this.editingUserData.NIP) {
          // NIP validation
          let nip = this.editingUserData.NIP.replace(/[\ \-]/gi, '');
          let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
          let sum = 0;
          let controlNumber = parseInt(nip.substring(9, 10));
          let weightCount = weight.length;
          for (let i = 0; i < weightCount; i++) {
            sum += parseInt(nip.substr(i, 1)) * weight[i];
          }

          if (sum % 11 !== controlNumber) {
            return false;
          } else {
            return true;
          }
        }

        return false;
      },

      getData: async function () {
        if (this.user == null)
          return (this.editingUserData = JSON.parse(JSON.stringify(this.defaultEmptyUserData)));

        this.loading = true;

        const customerDetails = await axios({
          url: this.$store.state.url + '/api/customer/get-details',
          data: {
            token: this.$store.state.token,
            id: this.user.user_id,
            type: 'only',
          },
          method: 'POST',
        }).catch(err => {
          this.info = {
            message: err?.response?.data?.message
              ? err.response.data.message
              : 'Nie udało się pobrać danych z systemu',
            status: 'error',
          };
        });
        if (!customerDetails || customerDetails?.status != 200) {
          this.loading = false;
          this.info = {
            message: customerDetails?.data?.message
              ? customerDetails.data.message
              : 'Nie udało się pobrać danych z systemu',
            status: 'error',
          };
          return;
        }

        if (customerDetails.data?.customer?.user?.company) {
          const companyDetails = await axios({
            url: this.$store.state.url + '/api/customer/get-company',
            data: {
              token: this.$store.state.token,
              id: customerDetails.data.customer.user.company,
            },
            method: 'POST',
          }).catch(err => {
            this.info = {
              message: err?.response?.data?.message
                ? err.response.data.message
                : 'Nie udało się pobrać danych z systemu',
              status: 'error',
            };
          });

          if (!companyDetails || companyDetails?.data?.status != 200) {
            this.loading = false;
            this.info = {
              message: companyDetails?.data?.message
                ? companyDetails.data.message
                : 'Nie udało się pobrać danych z systemu',
              status: 'error',
            };
            return;
          }

          const {
            name: company_name,
            postcode: company_postcode,
            city: company_city,
            address: company_address,
            NIP,
          } = companyDetails.data.company;
          Object.assign(this.editingUserData, {
            isCompany: true,
            company_name,
            company_postcode,
            company_city,
            company_address,
            NIP,
          });
        }

        const {
          id: user_id,
          name,
          surname,
          login,
          email,
          phone,
          postcode,
          city,
          company,
          contractor_number,
          birth_date,
        } = customerDetails.data.customer.user;
        Object.assign(this.editingUserData, {
          user_id,
          name,
          surname,
          login,
          email,
          phone,
          postcode,
          city,
          company,
          contractor_number,
          birth_date: birth_date
            ? moment(new Date(birth_date)).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD'),
        });

        this.loading = false;
      },
      getUserInfo: async function () {
        this.loading = true;

        const result = await axios({
          url: this.$store.state.url + '/api/external/user',
          data: {
            token: this.$store.state.token,
            contractor_number: this.editingUserData.contractor_number,
          },
          method: 'POST',
        }).catch(err => {
          this.info = {
            message: err?.response?.data?.message
              ? err.response.data.message
              : 'Nie udało się pobrać danych z systemu Prestiż',
            status: 'error',
          };
        });

        this.loading = false;

        if (result?.data?.status != 200)
          return (this.info = {
            message: result?.data?.message || 'Nieznany status odpowiedzi',
            status: 'error',
          });

        const { email, name } = result.data.result;
        this.info = {
          message: result?.data?.result
            ? `Numer kontrahenta odpowiada użytkownikowi: ${name} ${email ? '(' + email + ')' : ''}`
            : 'Nieznany status odpowiedzi',
          status: 'success',
        };
      },

      submit: async function () {
        if (!this.$refs?.formUserData.validate())
          return (this.info = {
            message: 'Uzupełnij poprawnie wszystkie dane',
            status: 'error',
          });

        for (const key in this.editingUserData)
          if (
            typeof this.editingUserData[key] === 'string' ||
            this.editingUserData[key] instanceof String
          )
            this.editingUserData[key] = this.editingUserData[key].trim();

        this.loading = true;

        const result = await axios({
          url: this.$store.state.url + '/api/customer/first_login',
          data: {
            token: this.$store.state.token,
            user: this.editingUserData,
          },
          method: 'POST',
        }).catch(err => {
          this.info = {
            message: err?.response?.data?.message
              ? err.response.data.message
              : 'Nie udało się pobrać danych z systemu Prestiż',
            status: 'error',
          };
        });

        if (!result || result?.data?.status != 200) {
          this.loading = false;
          this.info = {
            message: result?.data?.message
              ? result.data.message
              : 'Nie udało się pobrać danych z systemu',
            status: 'error',
          };
          return;
        }

        Object.assign(this.user, {
          name: this.editingUserData.name,
          surname: this.editingUserData.surname,
          phone: this.editingUserData.phone,
          email: this.editingUserData.email,
        });

        this.loading = false;
        this.isActive = false;
      },
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style></style>
