<template>
  <v-dialog v-model="isActive" :width="width" no-click-animation persistent>
    <v-card>
      <v-toolbar flat color="black" dark>
        <v-toolbar-title>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline ma-0 pa-0"> Edycja uprawnień </v-list-item-title>
              <v-list-item-subtitle class="mt-n4">
                Tutaj możesz zmienić uprawnienia użytkownika
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="py-5 grey--text text--darken-4">
        <v-row align="center">
          <!-- <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
            <h3>Typy mebli:</h3>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12" class="py-1">
            <v-switch
              v-model="permissions.hard_furniture"
              color="primary"
              hide-details
              dense
              class="mt-0"
            >
              <template v-slot:label>
                <h4>Meble paczkowane</h4>
              </template>
            </v-switch>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12" class="py-1">
            <v-switch
              v-model="permissions.upholstered_furniture"
              color="primary"
              hide-details
              dense
              class="mt-0"
            >
              <template v-slot:label>
                <h4>Meble tapicerowane / Inne</h4>
              </template>
            </v-switch>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12" class="py-1">
            <v-switch v-model="permissions.chairs" color="primary" hide-details dense class="mt-0">
              <template v-slot:label>
                <h4>Krzesła</h4>
              </template>
            </v-switch>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12" class="py-1">
            <v-switch v-model="permissions.tables" color="primary" hide-details dense class="mt-0">
              <template v-slot:label>
                <h4>Stoły</h4>
              </template>
            </v-switch>
          </v-col> -->

          <v-col cols="12" sm="12" md="12" lg="12" class="pb-0">
            <h3>Typy zleceń:</h3>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12" class="py-1">
            <v-switch
              v-model="permissions.paid_collection"
              color="primary"
              hide-details
              dense
              class="mt-0"
            >
              <template v-slot:label>
                <h4>Odbiór z wyznaczonego adresu</h4>
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="isActive = false"
        >
          Anuluj
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          v-tilt
          x-large
          @click="submit"
        >
          Edytuj
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <InfoSnackBar v-model="info"></InfoSnackBar>

    <!-- progress circular -->
    <ProgressCircularDialog v-model="loading"></ProgressCircularDialog>
  </v-dialog>
</template>

<script>
  import axios from 'axios';
  import InfoSnackBar from './InfoSnackBar.vue';
  import ProgressCircularDialog from './ProgressCircularDialog.vue';

  export default {
    name: 'UserPermissions',
    components: {
      InfoSnackBar,
      ProgressCircularDialog,
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      user: {
        type: Object,
      },
      width: {
        type: String,
        default: '620px',
      },
    },
    data: () => ({
      info: null,
      loading: false,

      permissions: {
        hard_furniture: true,
        upholstered_furniture: false,
        chairs: false,
        tables: false,

        paid_collection: false,
      },
    }),
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
    watch: {
      isActive: function (next) {
        if (next === true && this.user)
          Object.assign(this.permissions, {
            hard_furniture: this.user.hard_furniture ? true : false,
            upholstered_furniture: this.user.upholstered_furniture ? true : false,
            chairs: this.user.chairs ? true : false,
            tables: this.user.tables ? true : false,

            paid_collection: this.user.paid_collection ? true : false,
          });
        else
          Object.assign(this.permissions, {
            hard_furniture: true,
            upholstered_furniture: false,
            chairs: false,
            tables: false,

            paid_collection: false,
          });
      },
    },
    methods: {
      openSnack: function (text = '', color = 'error') {
        this.info = {
          message: text,
          status: color,
        };
      },
      submit: async function () {
        if (
          !(
            this.permissions.hard_furniture ||
            this.permissions.upholstered_furniture ||
            this.permissions.chairs ||
            this.permissions.tables
          )
        )
          return this.openSnack('Przynajmniej jeden typ mebli musi być dostępny', 'error');

        this.loading = true;

        const result = await axios({
          url: this.$store.state.url + '/api/customer/permissions',
          data: {
            token: this.$store.state.token,
            id: this.user.user_id,
            permissions: this.permissions,
          },
          method: 'POST',
        }).catch(() => {
          this.openSnack('Zmiana uprawnień niepowiodła się. Spróbuj ponownie.', 'error');
        });

        this.loading = false;

        if (!result) return;
        if (result.data.status != 200)
          return this.openSnack('Zmiana uprawnień niepowiodła się. Spróbuj ponownie.', 'error');

        this.$emit('submit', this.permissions);
      },
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
