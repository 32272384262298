<template>
  <v-container fluid class="grey lighten-4">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Wszyscy klienci
          <v-spacer></v-spacer>
          <v-text-field v-model="search" label="Szukaj" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="tableHeaders"
          :items="users"
          :sort-by="['name', 'created']"
          :sort-desc="[false, true]"
          class="elevation-1"
          :search="search"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100, -1],
          }"
          :items-per-page="20"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Klienci aplikacji</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="600px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2" v-on="on">Nowy klient</v-btn>
                </template>
                <v-card>
                  <v-toolbar flat color="black" :dark="true">
                    <v-toolbar-title>Dodaj klienta</v-toolbar-title>
                    <v-spacer />
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-alert class="col-12" v-if="message" type="error">
                          {{ message }}
                        </v-alert>
                      </v-row>

                      <v-row align="center" justify="center">
                        <v-col cols="8" sm="8" md="8">
                          <v-text-field
                            v-model="editedItem.contractor_number"
                            label="Numer kontrahenta"
                            :rules="[
                              v => !!v || 'Pole jest wymagane',
                              v =>
                                new RegExp('^[0-9]{1,10}$').test(v) ||
                                'Numer kontrahenta wydaje się być niepoprawny',
                            ]"
                            maxlength="10"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4">
                          <v-btn color="primary" @click="getUserInfo()"> Sprawdź </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.login"
                            label="Login"
                            :rules="rulesLogin"
                            maxlength="30"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Imię"
                            :rules="rulesName"
                            maxlength="50"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.surname"
                            label="Nazwisko"
                            :rules="rulesSurname"
                            maxlength="50"
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.email"
                            label="Email"
                            type="email"
                            :rules="rulesEmail"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.phone"
                            label="Nr telefonu"
                            type="tel"
                            :rules="rulesPhone"
                            v-mask="['### ### ###']"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.pass"
                            label="Hasło"
                            type="password"
                            :rules="rulesPassword"
                            maxlength="32"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.repass"
                            label="Powtórz hasło"
                            type="password"
                            :rules="[
                              v => !!v || 'Pole jest wymagane',
                              v =>
                                new RegExp('^' + editedItem.pass + '$').test(v) ||
                                'Hasła różnią się!',
                            ]"
                            maxlength="32"
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      color="amber accent-3"
                      class="grey--text text--darken-4"
                      outlined
                      :x-large="true"
                      v-tilt
                      @click="close"
                    >
                      Anuluj
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="amber accent-3"
                      class="grey--text text--darken-4"
                      :x-large="true"
                      v-tilt
                      @click="save()"
                    >
                      Dodaj
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <a :href="'/uzytkownicy/' + item.user_id"> {{ item.name }} {{ item.surname }} </a>
          </template>

          <template v-slot:[`item.phone`]="{ item }">
            <a :href="'tel:' + item.phone">{{ item.phone }}</a>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <a :href="'mailto:' + item.email">{{ item.email }}</a>
          </template>

          <template v-slot:[`item.permissions`]="{ item }">
            <v-btn
              @click="
                permissionsUser = item;
                permissionsDialog = true;
              "
            >
              <p class="ma-1">Edytuj</p>
              <v-icon right> mdi-key </v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              @click="
                editUserDialog = true;
                editedUser = item;
              "
            >
              <p class="ma-1">Edytuj</p>
              <v-icon right> mdi-account </v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.meta_value`]="{ item }">
            <v-tooltip v-if="!item.verified" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :to="'/cennik/' + item.user_id"
                  v-bind="attrs"
                  v-on="on"
                  class="v-btn--outlined-red"
                >
                  <p class="ma-1">Edytuj</p>
                  <v-icon right> mdi-table </v-icon>
                </v-btn>
              </template>
              <span>Cennik zawiera ceny niezweryfikowane przez administratora</span>
            </v-tooltip>

            <v-btn v-else :to="'/cennik/' + item.user_id">
              <p class="ma-1">Edytuj</p>
              <v-icon right> mdi-table </v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.delete`]="{ item }">
            <v-btn class="ma-2" color="red" dark @click="removeUser(item)">
              Usuń
              <v-icon dark right> mdi-trash-can-outline </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <EditUserV2 v-model="editUserDialog" :user="editedUser"></EditUserV2>

    <!-- Edit permissions dialog -->
    <UserPermissions
      v-model="permissionsDialog"
      :user="permissionsUser"
      @submit="savePermissions"
    ></UserPermissions>

    <v-dialog v-model="conflictDialog" max-width="600px" @keydown.esc="solveConflict()" persistent>
      <v-card>
        <v-toolbar color="black" dark dense flat>
          <v-toolbar-title class="white--text">Przywracanie usuniętego użytkownika</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click.native="solveConflict()" color="white" class="px-0" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pa-4">
          Użytkownik o podanym adresie email istniał kiedyś w bazie danych, lecz został z niej
          usunięty. Istnieją dwie możliwości:<br /><br />
          <b> Przywrócenie użytkownika </b>
          o podanym adresie email (hasło i login zostaną zmienione na podane w formularzu).
          Spowoduje to, że użytkownik będzie miał pełny wgląd do starej historii przed usunięciem
          konta.<br /><br />
          <b> Utworzenie użytkownika </b>
          spowoduje utworzenie w pełni nowego użytkownika (użytkownik nie będzie miał dostępu do
          historii ze starego konta). Oprócz tego nowy użytkownik nie będzie mógł sam przypisać się
          do już istniejącej firmy (jedynie administrator ma taką możliwość podczas edycji
          użytkownika).
          <br />
          <small class="text-center"> (aby anulować kliknij krzyżyk w górnym prawym rogu) </small>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-btn @click.native="solveConflict(true)" color="accent"> Utworzenie użytkownika </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click.native="solveConflict(false)" color="primary">
            Przywrócenie użytkownika
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm dialog -->
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>

    <!-- Progress Circular dialog -->
    <v-dialog v-model="loading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5)">
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate size="64" color="amber"> </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-container>
</template>
<script>
  import { mdiPackageVariant, mdiPin, mdiContacts } from '@mdi/js';

  import axios from 'axios';
  import { mask } from 'vue-the-mask';

  import EditUserV2 from '../../components/EditUser.vue';
  import UserPermissions from '../../components/UserPermissions.vue';
  import ConfirmDialog from '../../components/Confirm.vue';

  export default {
    components: {
      EditUserV2,
      UserPermissions,
      ConfirmDialog,
    },
    props: {
      source: String,
    },
    directives: { mask },
    data: () => ({
      editUserDialog: false,
      loading: false,
      icons: {
        mdiPackageVariant,
        mdiPin,
        mdiContacts,
      },
      rulesLogin: [
        v => !!v || 'Pole jest wymagane',
        v => (v.length > 7 && v.length < 31) || 'Login musi mieć od 8 do 30 znaków',
      ],
      rulesName: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ ]{1,50}$/.test(v) ||
          'Imię ma niepoprawny format (mininimum 3, a maksymalnie 50 znaków, bez cyfr)',
      ],
      rulesSurname: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ ]{1,50}$/.test(v) ||
          'Nazwisko ma niepoprawny format (mininimum 3, a maksymalnie 50 znaków, bez cyfr)',
      ],
      rulesPhone: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^(\+[0-9]{2}[-. ]?)?[0-9]{3}[-. ]?[0-9]{3}[-. ]?[0-9]{3}$/.test(v) ||
          'Wprowadź poprawny nr telefonu',
      ],
      rulesEmail: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            v
          ) || 'Wprowadzono niepoprawny adres email',
      ],
      rulesPassword: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^(?:(?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))(?!.*(.)\1{2,})[A-Za-z0-9!~<>,;:_=?*+#."&§%°()\|\[\]\-\$\^\@\/]{8,32}$/.test(
            v
          ) ||
          'Hasło musi zawierać od 8 do 32 znaków oraz zawierać kombinację trzech typów znaków (małe litery, duże litery, cyfry, znaki specjalne). Nie może zawierać więcej niż dwa te same znaki z rzędu',
      ],
      tableHeaders: [
        {
          text: 'LP',
          align: 'left',
          value: 'id',
          filterable: false,
        },
        {
          text: 'Imię i nazwisko',
          value: 'name',
          filterable: true,
        },
        {
          text: 'Nr telefonu',
          value: 'phone',
          filterable: true,
        },
        {
          text: 'Adres email',
          value: 'email',
          filterable: true,
        },
        {
          text: 'Data dołączenia',
          value: 'created',
          filterable: false,
        },
        {
          text: 'Uprawnienia',
          value: 'permissions',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Dane użytkownika',
          value: 'edit',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Cennik',
          value: 'meta_value',
          sortable: false,
          filterable: false,
        },
        {
          text: 'Usuń',
          value: 'delete',
          sortable: false,
          filterable: false,
        },
      ],
      users: [],
      search: '',
      dialog: false,
      editedItem: {
        contractor_number: null,
        name: null,
        surname: null,
        login: '',
        email: null,
        pass: null,
        repass: null,
        phone: null,
      },
      defaultItem: {
        contractor_number: null,
        name: null,
        surname: null,
        login: '',
        email: null,
        pass: null,
        repass: null,
        phone: null,
      },
      message: null,
      editData: false,
      editedUser: null,

      permissionsDialog: false,
      permissionsUser: {},

      conflictDialog: false,
      conflictDialogPromise: {
        resolve: null,
        reject: null,
      },
    }),
    methods: {
      // customFilter: function (value, search) {
      //   if (search == null) return true;
      //   if (value == null || typeof value !== 'string') return false;

      //   const formatedAndSplittedSearch = search
      //     .toLocaleUpperCase()
      //     .trim()
      //     .replace(/\s\s+/g, ' ')
      //     .split(' ');
      //   const formatedValue = value.toString().toLocaleUpperCase().trim().replace(/\s\s+/g, ' ');
      //   if (formatedAndSplittedSearch.length === 1)
      //     return formatedValue.indexOf(formatedAndSplittedSearch[0]) !== -1;
      //   else
      //     for (let index1 = 0; index1 < formatedAndSplittedSearch.length; index1++) {
      //       for (let index2 = 0; index2 < formatedAndSplittedSearch.length; index2++) {
      //         if (
      //           index1 !== index2 &&
      //           formatedValue.indexOf(
      //             `${formatedAndSplittedSearch[index1]} ${formatedAndSplittedSearch[index2]}`
      //           ) !== -1
      //         )
      //           return true;
      //         else continue;
      //       }
      //     }

      //   return false;
      // },
      checkPriceTable: function (meta) {
        let obj = JSON.parse(meta);

        for (const [, c] of Object.entries(obj)) {
          for (let index = 0; index < c.length; index++) {
            if (c[index]['value'] <= 0) {
              return false;
            }
          }
        }

        return true;
      },
      close: function () {
        this.dialog = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        }, 300);
      },
      save: function (conflict) {
        for (const key in this.editedItem) {
          if (typeof this.editedItem[key] === 'string' || this.editedItem[key] instanceof String)
            this.editedItem[key] = this.editedItem[key].trim();
        }
        axios({
          url: this.$store.state.url + '/api/users/customer/register',
          data: {
            token: this.$store.state.token,
            user: this.editedItem,
            conflict: conflict,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status !== 200) this.message = resp.data.message;
            else window.location.reload();
          })
          .catch(err => {
            if (err.response.status === 409) {
              this.conflictDialog = true;
              new Promise((resolve, reject) => {
                this.conflictDialogPromise.resolve = resolve;
                this.conflictDialogPromise.reject = reject;
              })
                .then(result => {
                  this.save(result);
                  this.solveConflict(result);
                })
                .catch(() => {
                  this.solveConflict();
                });
            }
          });
      },
      savePermissions: function (permissions) {
        Object.assign(this.permissionsUser, {
          hard_furniture: permissions.hard_furniture ? true : false,
          upholstered_furniture: permissions.upholstered_furniture ? true : false,
          chairs: permissions.chairs ? true : false,
          tables: permissions.tables ? true : false,

          paid_collection: permissions.paid_collection ? true : false,
        });

        this.permissionsDialog = false;
      },
      editUser: async function (item) {
        let user_id = item.user_id;
        let actual_status = item.paid_collection;

        this.loading = true;
        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
        await delay(10000); /// waiting 1 second.
        axios({
          url: this.$store.state.url + '/api/customer/collection',
          data: {
            token: this.$store.state.token,
            id: user_id,
            paid_collection: actual_status ? false : true,
          },
          method: 'POST',
        })
          .then(resp => {
            this.loading = false;
            if (resp.data.status === 200) window.location.reload();
            else alert('Error');
          })
          .catch(() => {
            this.loading = false;
          });
      },
      removeUser: async function (item) {
        if (
          !(await this.$refs.confirmDialog.open(
            'Usuń użytkownika',
            `Czy jesteś pewny, że chcesz usunąć tego użytkownika ${item.name}?`,
            { color: 'red' }
          ))
        ) {
          return;
        }

        axios({
          url: this.$store.state.url + '/api/customer/delete',
          data: {
            token: this.$store.state.token,
            user_id: item.user_id,
          },
          method: 'POST',
        })
          .then(() => {
            window.location.reload();
          })
          .catch(() => {
            window.location.reload();
          });
      },
      solveConflict: function (agree) {
        if (agree === true) {
          this.conflictDialogPromise.resolve(true);
        } else if (agree === false) {
          this.conflictDialogPromise.resolve(false);
        } else {
          // esc
          this.conflictDialogPromise.reject(false);
        }
        this.conflictDialog = false;
      },
      getUserInfo: function () {
        this.loading = true;
        axios({
          url: this.$store.state.url + '/api/external/user',
          data: {
            token: this.$store.state.token,
            contractor_number: this.editedItem.contractor_number,
          },
          method: 'POST',
        })
          .then(resp => {
            this.loading = false;

            if (resp.data.status === 200) {
              // , longName, city, street, postcode, company, nip
              const { email, phone, name } = resp.data.result;
              if (!this.editedItem.email) this.editedItem.email = email;
              if (!this.editedItem.phone)
                this.editedItem.phone =
                  phone.replace(/\ +/g, '').length == 9 ? phone.replace(/\ +/g, '') : '';
              if (!this.editedItem.name) this.editedItem.name = name.substring(0, 50);
              if (!this.editedItem.surname) this.editedItem.surname = name.substring(0, 50);

              alert('OK');
            } else {
              alert(resp.data.message);
            }
          })
          .catch(() => {
            this.loading = false;
            alert('Error');
          });
      },
    },
    created() {
      axios({
        url: this.$store.state.url + '/api/users/get/customers',
        data: {
          token: this.$store.state.token,
          type: 'price',
          verifiedPricesFlag: true,
        },
        method: 'POST',
      }).then(resp => {
        let counter = 1;
        for (let [, t] of resp.data.users.entries()) {
          if (t.id > 20) {
            if (t.creating_date) {
              let date = new Date(t.creating_date);

              if (date.getDate()) {
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let dt = date.getDate();
                let hr = date.getHours();
                let min = date.getMinutes();

                if (dt < 10) dt = '0' + dt;
                if (month < 10) month = '0' + month;
                if (hr < 10) hr = '0' + hr;
                if (min < 10) min = '0' + min;

                t.creating_date = dt + '.' + month + '.' + year + ' ' + hr + ':' + min;
              } else {
                t.date_created = null;
              }
            }

            this.users.push({
              id: counter,
              user_id: t.id,
              name: `${t.name} ${t.surname}`,
              phone: t.phone,
              email: t.email,
              created: t.creating_date,
              paid_collection: t.paid_collection ? true : false,
              hard_furniture: t.hard_furniture ? true : false,
              upholstered_furniture: t.upholstered_furniture ? true : false,
              meta_value:
                t.meta_value != null && t.meta_value != '' && t.meta_value != '{}'
                  ? this.checkPriceTable(t.meta_value)
                  : null,
              verified: t.verified ? true : false,
              contractor_number: t.contractor_number,
            });
            counter++;
          }
        }
      });
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--outlined-red {
    border: thin solid #ff5252 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
  small {
    display: block;
  }
</style>
